import { reject } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { backendUrl } from '../../config';
// ----------------------------------------------------------------------
const initialState = {
  isLoading: false,
  error: false,
  elms: [],
  elm: null,
  currentElm: {
    patientId: null,
    type: null,
    description: null,
    macro: null,
    micro: null,
    bodyMapIndex: null,
    microX: null,
    microY: null
  },
  sortBy: null,
  filters: {
    userId: []
  }
};

const slice = createSlice({
  name: 'elm',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Elms
    getElmsSuccess(state, action) {
      state.isLoading = false;
      state.elms = action.payload;
    },

    // GET Elm
    getElmSuccess(state, action) {
      state.isLoading = false;
      state.currentElm = action.payload;
    },

    // DELETE Elm
    deleteElm(state, action) {
      state.elms = reject(state.elms, { id: action.payload });
    },

    //  SORT & FILTER Elm
    sortByElms(state, action) {
      state.sortBy = action.payload;
    },

    filterElms(state, action) {
      state.filters.userId = action.payload.userId;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteElm, filterElms, sortByElms } = slice.actions;

export function getElms(patientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}elm/list/${patientId}`);
      dispatch(slice.actions.getElmsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getElm(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}elm/${id}`);
      dispatch(slice.actions.getElmSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
