import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="64.000000pt"
        height="64.000000pt"
        viewBox="0 0 64.000000 64.000000"
        preserveAspectRatio="xMidYMid meet"
        style={{ maxWidth: 40, maxHeight: 40 }}
      >
        <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
          <path
            d="M0 520 l0 -120 128 0
            127 0 28 105 c15 57 30
            111 33 120 5 13 -14 15
            -155 15 l-161 0 0 -120z"
          />
          <path
            d="M365 538 c-15 -57 -30 -111 -33 -120 -4 -17 8 -18 152 -18 l156 0 0
            120 0 120 -124 0 -123 0 -28 -102z"
          />
          <path
            d="M2 163 l3 -158 64 -3 c35 -2 68 1 73 6 7 7 88 285 88 304 0 5 -52 8
            -115 8 l-116 0 3 -157z"
          />
          <path
            d="M299 318 c0 -2 -20 -73 -43 -158 l-42 -155 44 -3 c24 -2 46 -1 49 2
            3 2 15 46 28 96 25 98 41 131 32 65 -4 -22 -9 -68 -13 -103 l-6 -63 37 3 c36
            3 37 4 81 103 24 55 44 96 44 90 0 -5 -11 -50 -25 -100 -14 -49 -25 -91 -25
            -92 0 -2 21 -3 48 -3 l47 0 39 148 c22 81 41 153 44 160 3 9 -15 12 -72 12
            -42 0 -76 -4 -76 -9 0 -4 -13 -37 -30 -72 l-29 -64 6 73 6 72 -72 0 c-39 0
            -71 -1 -72 -2z"
          />
        </g>
      </svg>
    </Box>
  );
}
