import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import { backendUrl } from '../config';
// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  selectedLocationId: null,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    const selectedLocationId =
      user?.organisation && user?.organisation?.locations && user?.organisation?.locations?.length > 0
        ? user.organisation.locations[0].id
        : null;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      selectedLocationId,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user, roles } = action.payload;
    const selectedLocationId =
      user?.organisation && user?.organisation?.locations && user?.organisation?.locations?.length > 0
        ? user.organisation.locations[0].id
        : null;

    return {
      ...state,
      isAuthenticated: true,
      selectedLocationId,
      user,
      roles
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  SET_SELECTED_LOCATION_ID: (state, action) => {
    const { locationId } = action.payload;

    return {
      ...state,
      selectedLocationId: locationId
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  setSelectedLocationId: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get(`${backendUrl}user/me`);
          const user = response.data;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const response = await axios.post(`${backendUrl}auth/signin`, {
      email,
      password
    });
    const { accessToken, roles, user, doctor, location, organisation } = response.data;

    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        roles,
        doctor,
        location,
        organisation
      }
    });
  };

  const register = async (email, password, firstname, lastname) => {
    const response = await axios.post(`${backendUrl}auth/signup`, {
      email,
      password,
      firstname,
      lastname
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const setSelectedLocationId = async (locationId) => {
    dispatch({ type: 'SET_SELECTED_LOCATION_ID', payload: { locationId } });
  };

  const resetPassword = () => {};

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
        setSelectedLocationId
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
