import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user';
import roleReducer from './slices/role';
import productReducer from './slices/product';
import licenseReducer from './slices/license';
import deviceReducer from './slices/device';
import serviceOrderReducer from './slices/serviceOrder';
import calendarReducer from './slices/calendar';
import organisationReducer from './slices/organisation';
import locationReducer from './slices/location';
import doctorReducer from './slices/doctor';
import patientReducer from './slices/patient';
import elmReducer from './slices/elm';
import planReducer from './slices/plan';
import paymentMethodReducer from './slices/paymentMethod';
import salesInvoiceReducer from './slices/salesInvoice';
import notificationReducer from './slices/notification';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  user: userReducer,
  role: roleReducer,
  license: licenseReducer,
  device: deviceReducer,
  serviceOrder: serviceOrderReducer,
  calendar: calendarReducer,
  organisation: organisationReducer,
  location: locationReducer,
  doctor: doctorReducer,
  patient: patientReducer,
  elm: elmReducer,
  paymentMethod: paymentMethodReducer,
  plan: planReducer,
  salesInvoice: salesInvoiceReducer,
  notification: notificationReducer,
  product: persistReducer(productPersistConfig, productReducer)
});

export { rootPersistConfig, rootReducer };
