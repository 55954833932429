import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { backendUrl } from '../../config';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  myProfile: null,
  posts: [],
  patients: [],
  followers: [],
  friends: [],
  gallery: [],
  cards: null,
  addressBook: [],
  invoices: [],
  currentPatient: null,
  notifications: null
};

const slice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PATIENTS
    getPatientsSuccess(state, action) {
      state.isLoading = false;
      state.patients = action.payload;
    },

    // GET PATIENT
    getPatientSuccess(state, action) {
      state.isLoading = false;
      state.currentPatient = action.payload;
    },

    // DELETE PATIENT
    deletePatient(state, action) {
      const deletePatient = filter(state.patients, (patient) => patient.id !== action.payload);
      state.patients = deletePatient;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow } = slice.actions;

// ----------------------------------------------------------------------

export function getPatients() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}patient`);
      dispatch(slice.actions.getPatientsSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function searchPatients(keyword) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}patient/search/${keyword}`);
      dispatch(slice.actions.getPatientsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPatient(patientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}patient/${patientId}`);
      dispatch(slice.actions.getPatientSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deletePatient(patientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${backendUrl}patient/${patientId}`);
      dispatch(slice.actions.deletePatient(patientId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
