// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_MANAGEMENT = '/management';
const ROOTS_CLINIC = '/clinic';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics')
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban')
};
export const PATH_CLINIC = {
  booking: {
    root: path(ROOTS_CLINIC, '/booking/'),
    list: path(ROOTS_CLINIC, '/booking/list'),
    newBooking: path(ROOTS_CLINIC, '/booking/new'),
    editById: path(ROOTS_CLINIC, '/booking/edit/id')
  },
  patient: {
    root: path(ROOTS_CLINIC, '/patient'),
    list: path(ROOTS_CLINIC, '/patient/list'),
    view: path(ROOTS_CLINIC, '/patient/view/'),
    newPatient: path(ROOTS_CLINIC, '/patient/new'),
    edit: path(ROOTS_CLINIC, '/patient/edit/'),
    captureMacro: path(ROOTS_CLINIC, '/patient/capture/macro/'),
    captureMicro: path(ROOTS_CLINIC, '/patient/capture/micro/'),
    captureAll: path(ROOTS_CLINIC, '/patient/capture/all/'),
    followUp: path(ROOTS_CLINIC, '/patient/elm/follow-up/'),
    report: path(ROOTS_CLINIC, '/patient/report/'),
    elm: path(ROOTS_CLINIC, '/patient/elm/'),
    elmView: path(ROOTS_CLINIC, '/patient/elm/view/')
  },
  doctor: {
    root: path(ROOTS_CLINIC, '/doctor'),
    profile: path(ROOTS_CLINIC, '/doctor/profile'),
    list: path(ROOTS_CLINIC, '/doctor/list'),
    newDoctor: path(ROOTS_CLINIC, '/doctor/new'),
    editById: path(ROOTS_CLINIC, '/doctor/edit/id')
  }
};
export const PATH_MANAGEMENT = {
  user: {
    root: path(ROOTS_MANAGEMENT, '/user'),
    profile: path(ROOTS_MANAGEMENT, '/user/profile'),
    list: path(ROOTS_MANAGEMENT, '/user/list'),
    newUser: path(ROOTS_MANAGEMENT, '/user/new'),
    editById: path(ROOTS_MANAGEMENT, '/user/ada-lindgren/edit'),
    account: path(ROOTS_MANAGEMENT, '/user/account')
  },
  license: {
    root: path(ROOTS_MANAGEMENT, '/license'),
    list: path(ROOTS_MANAGEMENT, '/license/list'),
    newLicense: path(ROOTS_MANAGEMENT, '/license/new'),
    editById: path(ROOTS_MANAGEMENT, '/license/edit/id')
  },
  organisation: {
    root: path(ROOTS_MANAGEMENT, '/organisation'),
    list: path(ROOTS_MANAGEMENT, '/organisation/list'),
    newOrganisation: path(ROOTS_MANAGEMENT, '/organisation/new'),
    editById: path(ROOTS_MANAGEMENT, '/organisation/edit/id')
  },
  plan: {
    root: path(ROOTS_MANAGEMENT, '/plan'),
    list: path(ROOTS_MANAGEMENT, '/plan/list'),
    newPlan: path(ROOTS_MANAGEMENT, '/plan/new'),
    editById: path(ROOTS_MANAGEMENT, '/plan/edit/id')
  },
  invoice: {
    root: path(ROOTS_MANAGEMENT, '/invoice'),
    list: path(ROOTS_MANAGEMENT, '/invoice/list')
  },
  paymentMethod: {
    root: path(ROOTS_MANAGEMENT, '/payment'),
    list: path(ROOTS_MANAGEMENT, '/payment/list'),
    newPaymentMethod: path(ROOTS_MANAGEMENT, '/payment/new'),
    editById: path(ROOTS_MANAGEMENT, '/payment/edit/id')
  },
  location: {
    root: path(ROOTS_MANAGEMENT, '/location'),
    list: path(ROOTS_MANAGEMENT, '/location/list'),
    newLocation: path(ROOTS_MANAGEMENT, '/location/new'),
    editById: path(ROOTS_MANAGEMENT, '/location/edit/id')
  },
  device: {
    root: path(ROOTS_MANAGEMENT, '/device'),
    list: path(ROOTS_MANAGEMENT, '/device/list'),
    newDevice: path(ROOTS_MANAGEMENT, '/device/new'),
    editById: path(ROOTS_MANAGEMENT, '/device/edit/id'),
    importDevice: path(ROOTS_MANAGEMENT, '/device/import')
  },
  catalog: {
    root: path(ROOTS_MANAGEMENT, '/catalog'),
    list: path(ROOTS_MANAGEMENT, '/catalog/product/list'),
    newProduct: path(ROOTS_MANAGEMENT, '/catalog/product/new'),
    product: path(ROOTS_MANAGEMENT, '/catalog/product/:name'),
    productById: path(ROOTS_MANAGEMENT, '/catalog/product/nike-air-force-1-ndestrukt'),
    editById: path(ROOTS_MANAGEMENT, '/catalog/product/edit/nike-blazer-low-77-vintage'),
    deviceList: path(ROOTS_MANAGEMENT, '/catalog/device/list'),
    deviceImport: path(ROOTS_MANAGEMENT, '/catalog/device/import')
  },
  service: {
    root: path(ROOTS_MANAGEMENT, '/service/order'),
    list: path(ROOTS_MANAGEMENT, '/service/order/list'),
    newOrder: path(ROOTS_MANAGEMENT, '/service/order/new'),
    editById: path(ROOTS_MANAGEMENT, '/catalog/product/edit/id')
  },
  setting: {
    root: path(ROOTS_MANAGEMENT, '/setting')
  }
};
