import { reject } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { backendUrl } from '../../config';
// ----------------------------------------------------------------------
const initialState = {
  isLoading: false,
  error: false,
  notifications: []
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Notifications
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },

    // GET Notification
    getNotificationSuccess(state, action) {
      state.isLoading = false;
      state.currentNotification = action.payload;
    },

    // DELETE Notification
    deleteNotification(state, action) {
      state.notifications = reject(state.notifications, { id: action.payload });
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteNotification } = slice.actions;

export function getNotifications() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}notification/`);
      dispatch(slice.actions.getNotificationsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNotification(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}notification/${id}`);
      dispatch(slice.actions.getNotificationSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function markNotificationAsRead(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}notification/read/${id}`);
      dispatch(slice.actions.getNotificationSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function markAllNotificationAsRead() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}notification/read/all`);
      dispatch(slice.actions.getNotificationSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
