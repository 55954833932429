import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: '/', element: <Dashboard /> }]
    },

    // Clinic Routes
    {
      path: 'clinic',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'patient',
          children: [
            { path: '/', element: <Navigate to="/clinic/patient/list" replace /> },
            { path: 'list', element: <PatientList /> },
            { path: 'new', element: <PatientCreate /> },
            { path: 'view/:id', element: <PatientView /> },
            { path: 'edit/:id', element: <PatientCreate /> },
            { path: 'report/:id', element: <PatientReport /> },
            { path: 'capture/:type/:id', element: <Capture /> },
            { path: 'elm/:id', element: <ElmList /> },
            { path: 'elm/view/:id', element: <ElmView /> },
            { path: 'elm/follow-up/:type/:id', element: <FollowUp /> }
          ]
        },
        {
          path: 'booking',
          children: [{ path: '/', element: <BookingCalendar /> }]
        },
        {
          path: 'doctor',
          children: [
            { path: '/', element: <Navigate to="/clinic/doctor/list" replace /> },
            { path: 'list', element: <DoctorList /> },
            { path: 'new', element: <DoctorCreate /> },
            { path: 'edit/:id', element: <DoctorCreate /> }
          ]
        }
      ]
    },

    // Management Routes
    {
      path: 'management',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'user',
          children: [
            { path: '/', element: <Navigate to="/management/user/list" replace /> },
            { path: 'profile', element: <UserProfile /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: 'edit/:id', element: <UserCreate /> }
          ]
        },
        {
          path: 'organisation',
          children: [
            { path: '/', element: <Navigate to="/management/organisation/list" replace /> },
            { path: 'list', element: <OrganisationList /> },
            { path: 'new', element: <OrganisationCreate /> },
            { path: 'edit/:id', element: <OrganisationCreate /> }
          ]
        },
        {
          path: 'payment',
          children: [
            { path: '/', element: <Navigate to="/management/payment/list" replace /> },
            { path: 'list', element: <PaymentMethodList /> },
            { path: 'new', element: <PaymentMethodCreate /> },
            { path: 'edit/:id', element: <PaymentMethodCreate /> }
          ]
        },
        {
          path: 'invoice',
          children: [
            { path: '/', element: <Navigate to="/management/invoice/list" replace /> },
            { path: 'list', element: <InvoiceList /> }
          ]
        },
        {
          path: 'plan',
          children: [
            { path: '/', element: <Navigate to="/management/plan/list" replace /> },
            { path: 'list', element: <PlanList /> },
            { path: 'new', element: <PlanCreate /> },
            { path: 'edit/:id', element: <PlanCreate /> }
          ]
        },
        {
          path: 'location',
          children: [
            { path: '/', element: <Navigate to="/management/location/list" replace /> },
            { path: 'list', element: <LocationList /> },
            { path: 'new', element: <LocationCreate /> },
            { path: 'edit/:id', element: <LocationCreate /> }
          ]
        },
        {
          path: 'catalog',
          children: [
            { path: '/', element: <Navigate to="/dashboard/catalog/product/list" replace /> },
            { path: 'product/list', element: <CatalogProductList /> },
            { path: 'product/new', element: <CatalogProductCreate /> },
            { path: 'product/edit/:id', element: <CatalogProductCreate /> },
            { path: 'device/list', element: <CatalogDeviceList /> },
            { path: 'device/import', element: <CatalogDeviceImport /> }
          ]
        },
        {
          path: 'license',
          children: [
            { path: '/', element: <Navigate to="/dashboard/license/list" replace /> },
            { path: 'list', element: <LicenseList /> },
            { path: 'edit/:id', element: <LicenseCreate /> },
            { path: 'new', element: <LicenseCreate /> }
          ]
        },
        {
          path: 'device',
          children: [
            { path: '/', element: <Navigate to="/dashboard/device/list" replace /> },
            { path: 'list', element: <DeviceList /> },
            { path: 'edit/:id', element: <DeviceCreate /> },
            { path: 'new', element: <DeviceCreate /> },
            { path: 'import', element: <DeviceImport /> }
          ]
        },
        {
          path: 'service/order',
          children: [
            { path: '/', element: <Navigate to="/dashboard/service/order/list" replace /> },
            { path: 'list', element: <ServiceOrderList /> },
            { path: 'edit/:id', element: <ServiceOrderCreate /> },
            { path: 'new', element: <ServiceOrderCreate /> }
          ]
        },
        { path: 'setting', element: <Setting /> }
      ]
    },
    { path: '*', element: <Navigate to="/dashboard" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));

// Dashboard
const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Index')));
const Setting = Loadable(lazy(() => import('../pages/management/setting/Index')));

// Clinic
const PatientList = Loadable(lazy(() => import('../pages/clinic/patient/PatientList')));
const PatientCreate = Loadable(lazy(() => import('../pages/clinic/patient/PatientCreate')));
const PatientView = Loadable(lazy(() => import('../pages/clinic/patient/PatientView')));
const PatientReport = Loadable(lazy(() => import('../pages/clinic/patient/PatientReport')));
const Capture = Loadable(lazy(() => import('../pages/clinic/elm/Capture')));
const FollowUp = Loadable(lazy(() => import('../pages/clinic/elm/FollowUp')));
const ElmList = Loadable(lazy(() => import('../pages/clinic/elm/List')));
const ElmView = Loadable(lazy(() => import('../pages/clinic/elm/View')));
const DoctorList = Loadable(lazy(() => import('../pages/clinic/doctor/DoctorList')));
const DoctorCreate = Loadable(lazy(() => import('../pages/clinic/doctor/DoctorCreate')));
const BookingCalendar = Loadable(lazy(() => import('../pages/clinic/booking/Calendar')));

// Management
const CatalogProductList = Loadable(lazy(() => import('../pages/management/catalog/ProductList')));
const CatalogProductCreate = Loadable(lazy(() => import('../pages/management/catalog/ProductCreate')));
const CatalogDeviceList = Loadable(lazy(() => import('../pages/management/license/DeviceList')));
const CatalogDeviceImport = Loadable(lazy(() => import('../pages/management/license/DeviceImport')));

const OrganisationList = Loadable(lazy(() => import('../pages/management/organisation/OrganisationList')));
const OrganisationCreate = Loadable(lazy(() => import('../pages/management/organisation/OrganisationCreate')));
const InvoiceList = Loadable(lazy(() => import('../pages/management/sales/InvoiceList')));
const PlanList = Loadable(lazy(() => import('../pages/management/plan/PlanList')));
const PlanCreate = Loadable(lazy(() => import('../pages/management/plan/PlanCreate')));
const PaymentMethodList = Loadable(lazy(() => import('../pages/management/payment/PaymentMethodList')));
const PaymentMethodCreate = Loadable(lazy(() => import('../pages/management/payment/PaymentMethodCreate')));
const LocationList = Loadable(lazy(() => import('../pages/management/location/LocationList')));
const LocationCreate = Loadable(lazy(() => import('../pages/management/location/LocationCreate')));

const LicenseList = Loadable(lazy(() => import('../pages/management/license/LicenseList')));
const LicenseCreate = Loadable(lazy(() => import('../pages/management/license/LicenseCreate')));

const DeviceList = Loadable(lazy(() => import('../pages/management/license/DeviceList')));
const DeviceCreate = Loadable(lazy(() => import('../pages/management/license/DeviceCreate')));
const DeviceImport = Loadable(lazy(() => import('../pages/management/license/DeviceImport')));

const ServiceOrderList = Loadable(lazy(() => import('../pages/management/service/ServiceOrderList')));
const ServiceOrderCreate = Loadable(lazy(() => import('../pages/management/service/ServiceOrderCreate')));

const UserProfile = Loadable(lazy(() => import('../pages/management/user/UserProfile')));
const UserList = Loadable(lazy(() => import('../pages/management/user/UserList')));
const UserCreate = Loadable(lazy(() => import('../pages/management/user/UserCreate')));
