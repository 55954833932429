import { reject } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { backendUrl } from '../../config';
// ----------------------------------------------------------------------
const initialState = {
  isLoading: false,
  error: false,
  paymentMethods: [],
  paymentMethod: null,
  sortBy: null,
  filters: {
    userId: []
  }
};

const slice = createSlice({
  name: 'paymentMethod',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Payment Methods
    getPaymentMethodsSuccess(state, action) {
      state.isLoading = false;
      state.paymentMethods = action.payload;
    },

    // GET Payment Method
    getPaymentMethodSuccess(state, action) {
      state.isLoading = false;
      state.currentPaymentMethod = action.payload;
    },

    // DELETE Payment Method
    deletePaymentMethod(state, action) {
      state.paymentMethods = reject(state.paymentMethods, { id: action.payload });
    },

    //  SORT & FILTER Payment Method
    sortByPaymentMethods(state, action) {
      state.sortBy = action.payload;
    },

    filterPaymentMethods(state, action) {
      state.filters.userId = action.payload.userId;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { deletePaymentMethod, filterPaymentMethods, sortByPaymentMethods } = slice.actions;

export function getPaymentMethods() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}payment`);
      dispatch(slice.actions.getPaymentMethodsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPaymentMethodsByOrgId(organisationId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}payment/organisation/${organisationId}`);
      dispatch(slice.actions.getPaymentMethodsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPaymentMethod(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}payment/${id}`);
      dispatch(slice.actions.getPaymentMethodSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
