import { reject } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { backendUrl } from '../../config';
// ----------------------------------------------------------------------
const initialState = {
  isLoading: false,
  error: false,
  doctors: [],
  doctor: null,
  sortBy: null,
  filters: {
    userId: []
  }
};

const slice = createSlice({
  name: 'doctor',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Doctors
    getDoctorsSuccess(state, action) {
      state.isLoading = false;
      state.doctors = action.payload;
    },

    // GET Doctor
    getDoctorSuccess(state, action) {
      state.isLoading = false;
      state.currentDoctor = action.payload;
    },

    // DELETE Doctor
    deleteDoctor(state, action) {
      state.doctors = reject(state.doctors, { id: action.payload });
    },

    //  SORT & FILTER Doctor
    sortByDoctors(state, action) {
      state.sortBy = action.payload;
    },

    filterDoctors(state, action) {
      state.filters.userId = action.payload.userId;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteDoctor, filterDoctors, sortByDoctors } = slice.actions;

export function getDoctors() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}doctor`);
      dispatch(slice.actions.getDoctorsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDoctor(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}doctor/${id}`);
      dispatch(slice.actions.getDoctorSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
