import { reject } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { backendUrl } from '../../config';
// ----------------------------------------------------------------------
const initialState = {
  isLoading: false,
  error: false,
  salesInvoices: [],
  salesInvoice: null,
  sortBy: null,
  filters: {
    userId: []
  }
};

const slice = createSlice({
  name: 'salesInvoice',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Sales Invoices
    getSalesInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.salesInvoices = action.payload;
    },

    // GET Sales Invoice
    getSalesInvoiceSuccess(state, action) {
      state.isLoading = false;
      state.currentSalesInvoice = action.payload;
    },

    // DELETE Sales Invoice
    deleteSalesInvoice(state, action) {
      state.salesInvoices = reject(state.salesInvoices, { id: action.payload });
    },

    //  SORT & FILTER Sales Invoice
    sortBySalesInvoices(state, action) {
      state.sortBy = action.payload;
    },

    //  SORT & FILTER Sales Invoice
    markAsPaid(state, action) {
      state.salesInvoices = state.salesInvoices.map((invoice) =>
        invoice.id === action.payload ? { ...invoice, status: 1 } : invoice
      );
    },

    filterSalesInvoices(state, action) {
      state.filters.userId = action.payload.userId;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteSalesInvoice, filterSalesInvoices, sortBySalesInvoices } = slice.actions;

export function getSalesInvoices() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}sales/invoice`);
      dispatch(slice.actions.getSalesInvoicesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function markAsPaid(id) {
  return async (dispatch) => {
    dispatch(slice.actions.markAsPaid(id));
  };
}

export function getSalesInvoice(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}sales/invoice/${id}`);
      dispatch(slice.actions.getSalesInvoiceSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
