import { reject } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { backendUrl } from '../../config';
// ----------------------------------------------------------------------
const initialState = {
  isLoading: false,
  error: false,
  organisations: [],
  organisation: null,
  sortBy: null,
  filters: {
    userId: []
  }
};

const slice = createSlice({
  name: 'organisation',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Organisations
    getOrganisationsSuccess(state, action) {
      state.isLoading = false;
      state.organisations = action.payload;
    },

    // GET Organisation
    getOrganisationSuccess(state, action) {
      state.isLoading = false;
      state.currentOrganisation = action.payload;
    },

    // DELETE Organisation
    deleteOrganisation(state, action) {
      state.organisations = reject(state.organisations, { id: action.payload });
    },

    //  SORT & FILTER Organisation
    sortByOrganisations(state, action) {
      state.sortBy = action.payload;
    },

    filterOrganisations(state, action) {
      state.filters.userId = action.payload.userId;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteOrganisation, filterOrganisations, sortByOrganisations } = slice.actions;

export function getOrganisations() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}organisation`);
      dispatch(slice.actions.getOrganisationsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getOrganisation(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}organisation/${id}`);
      dispatch(slice.actions.getOrganisationSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
