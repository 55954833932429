import {
  FaHospital,
  FaHospitalUser,
  FaLocationDot,
  FaUser,
  FaUserDoctor,
  FaCalendarDays,
  FaSliders,
  FaCcVisa,
  FaMoneyBill
} from 'react-icons/fa6';

// routes
import { PATH_DASHBOARD, PATH_MANAGEMENT, PATH_CLINIC } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';
// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  kanban: getIcon('ic_kanban')
};

const sidebarConfig = [
  {
    subheader: 'general',
    roles: ['super', 'administrator', 'general', 'doctor'],
    items: [
      {
        title: 'dashboard',
        path: PATH_DASHBOARD.root,
        icon: ICONS.dashboard,
        roles: ['super', 'administrator', 'general', 'doctor']
      }
    ]
  },
  {
    subheader: 'clinic',
    roles: ['super', 'administrator', 'general', 'doctor'],
    items: [
      {
        title: 'bookings',
        path: PATH_CLINIC.booking.root,
        icon: <FaCalendarDays />,
        roles: ['super', 'administrator', 'general', 'doctor']
      },
      {
        title: 'patients',
        path: PATH_CLINIC.patient.root,
        icon: <FaHospitalUser />,
        roles: ['super', 'administrator', 'doctor']
      },
      {
        title: 'doctors',
        path: PATH_CLINIC.doctor.root,
        icon: <FaUserDoctor />,
        roles: ['super', 'administrator', 'general']
      }
    ]
  },
  {
    subheader: 'management',
    roles: ['super', 'administrator', 'general'],
    items: [
      {
        title: 'users',
        path: PATH_MANAGEMENT.user.root,
        icon: <FaUser />,
        roles: ['super', 'administrator', 'general']
      },
      {
        title: 'locations',
        path: PATH_MANAGEMENT.location.root,
        icon: <FaLocationDot />,
        roles: ['super', 'administrator', 'general']
      },
      {
        title: 'payment methods',
        path: PATH_MANAGEMENT.paymentMethod.root,
        icon: <FaCcVisa />,
        roles: ['super', 'administrator']
      },
      {
        title: 'organisations',
        path: PATH_MANAGEMENT.organisation.root,
        icon: <FaHospital />,
        roles: ['super']
      },
      {
        title: 'plan',
        path: PATH_MANAGEMENT.plan.root,
        icon: <FaSliders />,
        roles: ['super']
      },
      {
        title: 'invoice',
        path: PATH_MANAGEMENT.invoice.root,
        icon: <FaMoneyBill />,
        roles: ['super', 'administrator']
      }
    ]
  }
  /*
  {
    subheader: 'app',
    items: [
      {
        title: 'products',
        path: PATH_MANAGEMENT.catalog.root,
        icon: ICONS.calendar,
        children: [
          { title: 'list', path: PATH_MANAGEMENT.catalog.list },
          { title: 'create', path: PATH_MANAGEMENT.catalog.newProduct }
        ]
      },
      {
        title: 'licenses',
        path: PATH_MANAGEMENT.license.root,
        icon: ICONS.ecommerce,
        children: [
          { title: 'licenses', path: PATH_MANAGEMENT.license.list },
          { title: 'new license', path: PATH_MANAGEMENT.license.newLicense },
          { title: 'device', path: PATH_MANAGEMENT.device.list },
          { title: 'new device', path: PATH_MANAGEMENT.device.newDevice },
          { title: 'import devices', path: PATH_MANAGEMENT.device.importDevice }
        ]
      },
      {
        title: 'service orders',
        path: PATH_MANAGEMENT.service.list,
        icon: ICONS.kanban,
        children: [
          { title: 'service orders', path: PATH_MANAGEMENT.service.list },
          { title: 'new service orders', path: PATH_MANAGEMENT.service.newOrder }
        ]
      },
      {
        title: 'mail',
        path: PATH_DASHBOARD.mail.root,
        icon: ICONS.mail,
        info: <Label color="error">2</Label>
      },
      { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
      { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
      {
        title: 'kanban',
        path: PATH_DASHBOARD.kanban,
        icon: ICONS.kanban
      }
    ]
  }
  */
];

export default sidebarConfig;
