import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { FaBuilding, FaCheck } from 'react-icons/fa';
// material
import { alpha } from '@material-ui/core/styles';
import { Box, List, Badge, Typography, ListItemText, ListItemAvatar, ListItemButton } from '@material-ui/core';
// utils
import createAvatar from '../../utils/createAvatar';
// hooks
import useAuth from '../../hooks/useAuth';
// components
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { MIconButton, MAvatar } from '../../components/@material-extend';

// ----------------------------------------------------------------------

function renderContent(location) {
  const title = (
    <Typography variant="subtitle2">
      {location.title}
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {location.description}
      </Typography>
    </Typography>
  );

  return { title };
}

LocationItem.propTypes = {
  selected: PropTypes.bool,
  location: PropTypes.object.isRequired,
  setSelectedLocationId: PropTypes.func
};

function LocationItem({ location, selected, setSelectedLocationId }) {
  const { title } = renderContent(location);

  return (
    <ListItemButton
      to="#"
      disableGutters
      onClick={() => setSelectedLocationId(location.id)}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(selected && {
          bgcolor: 'action.selected'
        })
      }}
    >
      <ListItemAvatar>
        <MAvatar alt={location.title}>{createAvatar(location.title).name}</MAvatar>
      </ListItemAvatar>
      <ListItemText primary={title} />
      {selected && <FaCheck />}
    </ListItemButton>
  );
}

export default function LocationPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { user, selectedLocationId, setSelectedLocationId } = useAuth();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!user.organisation || user.organisation?.locations?.length === 0) {
    return <></>;
  }

  return (
    <>
      <MIconButton
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          ...(open
            ? {
                bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
              }
            : {
                bgcolor: '#C4CDD5'
              })
        }}
      >
        <Badge>
          <FaBuilding size="16" />
        </Badge>
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 360 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Select Location</Typography>
          </Box>
        </Box>

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List disablePadding>
            {user.organisation.locations.map((location) => (
              <LocationItem
                key={location.id}
                selected={location.id === selectedLocationId}
                setSelectedLocationId={setSelectedLocationId}
                location={{
                  id: location.id,
                  title: location.name,
                  description: `${location.suburb} ${location.state} ${location.postcode}`
                }}
              />
            ))}
          </List>
        </Scrollbar>
      </MenuPopover>
    </>
  );
}
