import { reject } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { backendUrl } from '../../config';
// ----------------------------------------------------------------------
const initialState = {
  isLoading: false,
  error: false,
  licenses: [],
  license: null,
  sortBy: null,
  filters: {
    userId: []
  }
};

const slice = createSlice({
  name: 'license',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Licenses
    getLicensesSuccess(state, action) {
      state.isLoading = false;
      state.licenses = action.payload;
    },

    // GET License
    getLicenseSuccess(state, action) {
      state.isLoading = false;
      state.currentLicense = action.payload;
    },

    // DELETE License
    deleteLicense(state, action) {
      state.licenses = reject(state.licenses, { id: action.payload });
    },

    //  SORT & FILTER License
    sortByLicenses(state, action) {
      state.sortBy = action.payload;
    },

    filterLicenses(state, action) {
      state.filters.userId = action.payload.userId;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteLicense, filterLicenses, sortByLicenses } = slice.actions;

export function getLicenses() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}license`);
      dispatch(slice.actions.getLicensesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getLicense(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}license/${id}`);
      dispatch(slice.actions.getLicenseSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
