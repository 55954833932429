import { reject } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { backendUrl } from '../../config';
// ----------------------------------------------------------------------
const initialState = {
  isLoading: false,
  error: false,
  serviceOrders: [],
  serviceOrder: null,
  sortBy: null,
  filters: {
    userId: []
  }
};

const slice = createSlice({
  name: 'serviceOrders',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Service Orders
    getServiceOrdersSuccess(state, action) {
      state.isLoading = false;
      state.serviceOrders = action.payload;
    },

    // GET Service Order
    getServiceOrderSuccess(state, action) {
      state.isLoading = false;
      state.currentServiceOrder = action.payload;
    },

    // DELETE Service Order
    deleteServiceOrder(state, action) {
      state.serviceOrders = reject(state.serviceOrders, { id: action.payload });
    },

    //  SORT & FILTER Service Order
    sortByServiceOrders(state, action) {
      state.sortBy = action.payload;
    },

    filterServiceOrders(state, action) {
      state.filters.userId = action.payload.userId;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteServiceOrder, filterServiceOrders, sortByServiceOrders } = slice.actions;

export function getServiceOrders() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}service/order`);
      dispatch(slice.actions.getServiceOrdersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getServiceOrder(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}service/order/${id}`);
      dispatch(slice.actions.getServiceOrderSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
