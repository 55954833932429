import { reject } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { backendUrl } from '../../config';
// ----------------------------------------------------------------------
const initialState = {
  isLoading: false,
  error: false,
  devices: [],
  device: null,
  sortBy: null,
  filters: {
    userId: []
  }
};

const slice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Devices
    getDevicesSuccess(state, action) {
      state.isLoading = false;
      state.devices = action.payload;
    },

    // GET Device
    getDeviceSuccess(state, action) {
      state.isLoading = false;
      state.currentDevice = action.payload;
    },

    // DELETE Device
    deleteDevice(state, action) {
      state.devices = reject(state.devices, { id: action.payload });
    },

    //  SORT & FILTER Device
    sortByDevices(state, action) {
      state.sortBy = action.payload;
    },

    filterDevices(state, action) {
      state.filters.userId = action.payload.userId;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteDevice, filterDevices, sortByDevices } = slice.actions;

export function getDevices() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}device`);
      dispatch(slice.actions.getDevicesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDevice(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}device/${id}`);
      dispatch(slice.actions.getDeviceSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
