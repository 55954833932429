import { reject } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { backendUrl } from '../../config';
// ----------------------------------------------------------------------
const initialState = {
  isLoading: false,
  error: false,
  locations: [],
  location: null,
  sortBy: null,
  filters: {
    userId: []
  }
};

const slice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Locations
    getLocationsSuccess(state, action) {
      state.isLoading = false;
      state.locations = action.payload;
    },

    // GET Location
    getLocationSuccess(state, action) {
      state.isLoading = false;
      state.currentLocation = action.payload;
    },

    // DELETE Location
    deleteLocation(state, action) {
      state.locations = reject(state.locations, { id: action.payload });
    },

    //  SORT & FILTER Location
    sortByLocations(state, action) {
      state.sortBy = action.payload;
    },

    filterLocations(state, action) {
      state.filters.userId = action.payload.userId;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteLocation, filterLocations, sortByLocations } = slice.actions;

export function getLocations() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}location`);
      dispatch(slice.actions.getLocationsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getLocation(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}location/${id}`);
      dispatch(slice.actions.getLocationSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
