import { reject } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { backendUrl } from '../../config';
// ----------------------------------------------------------------------
const initialState = {
  isLoading: false,
  error: false,
  plans: [],
  plan: null,
  sortBy: null,
  filters: {
    userId: []
  }
};

const slice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Plans
    getPlansSuccess(state, action) {
      state.isLoading = false;
      state.plans = action.payload;
    },

    // GET Plan
    getPlanSuccess(state, action) {
      state.isLoading = false;
      state.currentPlan = action.payload;
    },

    // DELETE Plan
    deletePlan(state, action) {
      state.plans = reject(state.plans, { id: action.payload });
    },

    //  SORT & FILTER Plan
    sortByPlans(state, action) {
      state.sortBy = action.payload;
    },

    filterPlans(state, action) {
      state.filters.userId = action.payload.userId;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { deletePlan, filterPlans, sortByPlans } = slice.actions;

export function getPlans() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}plan`);
      dispatch(slice.actions.getPlansSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPlan(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}plan/${id}`);
      dispatch(slice.actions.getPlanSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
